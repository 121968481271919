// import Vue from 'vue'
import consts from "@/consts"
import {uniq} from "lodash/array";
import {getApiProps} from "@/lib/lib";
// import {objectComparison} from "@/lib/lib";
const changedField = 'changed__time';

export default {
    state: {
        productsLiteLoad: false,
        productsFullLoad: false,
        products: [],
    },
    actions: {
        fetchProducts/*all*/({dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchProducts', time: Date.now() / 1000})

                const params = getApiProps('products', args)
                this.$api.products.getAll({...params})
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchProducts', inprogress: false})
                    });
            })
        },
        fetchProductsAll({dispatch, commit, getters}) {
            // dispatch('setLastCall', {name: 'fetchProductsAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchProductsChanged', time: Date.now() / 1000})

            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(null)
                }
                dispatch('fetchProducts', {lite: true})
                    .then((data) => {
                        commit('setProducts', data)
                        commit('setProductsFullLoad', true)
                        resolve(data.length)
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchProductsAll', {})
                        }, 60 * 1000)
                    })
            })
                .finally(() => {
                    //dispatch('setLastCall', {name: 'fetchProductsAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchProductsChanged', inprogress: false})
                });
        },
        fetchProductsChanged({dispatch, commit, getters}, args) {
            if (!getters.apiToken || !getters.isProductsFullLoad) {
                return
            }
            dispatch('setLastCall', {name: 'fetchProductsChanged', time: Date.now() / 1000})

            args = {...consts.querySettings.filter, ...args}
            return dispatch('fetchProducts', args)
                .then((data) => {
                    commit('updateProducts', data)
                    return dispatch('fetchProducts', {fields: 'id', expand: ''})
                })
                .then((data) => {
                    commit('filterProducts', data)
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchProductsChanged', inprogress: false})
                });
        },

        saveProduct({dispatch}, product) {
            let fn = (product.id) ? 'updateProduct' : 'createProduct'
            return dispatch(fn, product);
        },
        createProduct({commit, dispatch}, product) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('products')
                this.$api.products.create(product, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('updateProduct', response.data)
                            dispatch('fetchProductsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateProduct({commit, dispatch}, product) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('products')
                this.$api.products.update(product.id, product, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('updateProduct', response.data)
                            dispatch('fetchProductsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteProduct({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                this.$api.products.delete(id)
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data?.error)) {
                            commit('deleteProduct', id)
                            dispatch('fetchProductsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
    },
    mutations: {
        setProductsLiteLoad(state, LiteLoad) {
            state.productsLiteLoad = state.productsLiteLoad || LiteLoad
        },
        setProductsFullLoad(state, FullLoad) {
            state.productsFullLoad = state.productsFullLoad || FullLoad
        },
        setProducts(state, nProducts) {
            state.products = nProducts.map(p => Object.freeze(p))
        },
        updateProducts(state, nProducts) {
            if (!state.products.length) {
                state.products = nProducts.map(p => Object.freeze(p))
                return true
            }

            nProducts.forEach(function (nProduct) {
                let i = state.products.findIndex(p => p.id == nProduct.id)
                if (i < 0) {
                    state.products.push(Object.freeze(nProduct))
                } else
                if (!state.productsFullLoad || state.products[i][changedField] != nProduct[changedField]) {
                    // updateObjectByDiff(state.products[i], nProduct)
                    delete nProduct.id
                    state.products[i] = Object.freeze({...state.products[i], ...nProduct})
                }
            })
        },
        filterProducts(state, nProducts) {
            let nIds = nProducts.map(np => np.id)
            let removedIds = state.products.filter(u => !nIds.includes(u.id)).map(u => u.id)
            removedIds.forEach(removedId => {
                let i = state.products.findIndex(u => (u.id == removedId))
                if (i != -1) {
                    state.products.splice(i, 1)
                }
            })
        },
        updateProduct(state, nProduct) {
            let i = state.products.findIndex(p => p.id == nProduct.id)
            if (i < 0) {
                state.products.push(Object.freeze(nProduct))
            } else
            if (!state.productsFullLoad || state.products[i][changedField] != nProduct[changedField]) {
                // updateObjectByDiff(state.products[i], nProduct)
                delete nProduct.id
                state.products[i] = Object.freeze({...state.products[i], ...nProduct})
            }
        },
        deleteProduct(state, id) {
            let i = state.products.findIndex(p => (p.id == id))
            if (i != -1) {
                state.products.splice(i, 1)
            }
        },
        clearProducts(state) {
            state.products = []
            state.productsLiteLoad = false
            state.productsFullLoad = false
        },
    },
    getters: {
        isProductsLiteLoad(state) {
            return state.productsLiteLoad
        },
        isProductsFullLoad(state) {
            return state.productsFullLoad
        },
        getProductsTypes(state) {
            return uniq(state.products.map(p => p.type))
        },
        getProductsByDays(state) {
            return state.products.reduce((productsByDays, product) => {
                let days = product.days
                if (!productsByDays[days]) productsByDays[days] = []
                productsByDays[days].push(product)
                return productsByDays
            }, {})
        },
        getProducts(state) {
            return state.products
        },
        getProductById(state) {
            return state.products.reduce((productsByIds, product) => {
                productsByIds[product.id] = product
                return productsByIds
            }, {})
        }
    }
}