// import store from '@/store';
// import moment from "moment";
import consts from "@/consts";
const routes = (process.env.VUE_APP_PACKAGE !== 'intuit.portal') ? [] : [
    {
        path: '/help',
        name: consts.routerNames.help.main,
        meta: {
            mainRouteName: consts.routerNames.help.main,
            hideMap: true,
            isLimited: true,
        },
        component: () => import('@/views/intuit/portal/HelpPage.vue')
    },
];

export default routes;