// import Vue from 'vue'
import consts from "@/consts"
import {getApiProps, hasFilter} from "@/lib/lib";
import range from "lodash/range";
// import {objectComparison} from "@/lib/lib";
const changedField = 'changed__time';

export default {
    state: {
        unitsKeysOrdersLiteLoad: false,
        unitsKeysOrdersFullLoad: false,
        unitsKeysOrders: [],
    },
    actions: {
        fetchUnitsKeysOrders/*all*/({dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchUnitsKeysOrders', time: Date.now() / 1000})

                const params = getApiProps('keysorders', args)
                this.$api.unitskeysorders.getAll({...params})
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchUnitsKeysOrders', inprogress: false})
                    });
            })
        },
        fetchUnitsKeysOrdersAll({dispatch, commit}, args) {
            return new Promise((resolve, reject) => {
                this.$api.init.getUnitsKeysOrders(args)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('setUnitsKeysOrders', response.data)
                            if(!hasFilter(args)) {
                                commit('setUnitsKeysOrdersLiteLoad', true)
                            }
                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchUnitsKeysOrdersAll', {})
                        }, 60 * 1000)
                    })
            })
        },
        _fetchUnitsKeysOrdersAll({dispatch}, args) {
            return dispatch('fetchUnitsKeysOrdersAllLite', args)
        },
        fetchUnitsKeysOrdersAllLite({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (getters.isUnitsKeysOrdersLiteLoad) {
                    return resolve(getters.unitsKeysOrders.length)
                }
                dispatch('fetchUnitsKeysOrders', {...args, lite: true})
                    .then((data) => {
                        commit('updateUnitsKeysOrders', data)
                        if(!hasFilter(args)) {
                            commit('setUnitsKeysOrdersLiteLoad', true)
                        }
                        resolve(data.length)
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchUnitsKeysOrdersAllLite', {})
                        }, 60 * 1000)
                    })
            })
        },
        fetchUnitsKeysOrdersAllPages({dispatch, commit, getters}, args) {
            //dispatch('setLastCall', {name: 'fetchUnitsKeysOrdersAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchUnitsKeysOrdersChanged', time: Date.now() / 1000})

            return new Promise((resolve, reject) => {
                    if (!getters.apiToken) {
                        return reject(null)
                    }
                    if (!getters.unitsKeysOrders.length) {
                        return resolve([])
                    }

                    let pageSize = consts.querySettings.pageSize
                    let pages = Math.ceil(getters.unitsKeysOrders.length / pageSize)
                    let fetch = range(pages).map(i => {
                        let page = i + 1;
                        return dispatch('fetchUnitsKeysOrders', {page, 'page-size': pageSize, ...args})
                            .then((data) => {
                                commit('updateUnitsKeysOrders', data)
                            })
                            .catch(() => {
                                dispatch('fetchUnitsKeysOrders', {page, 'page-size': pageSize, ...args})
                            })
                    });
                    resolve(fetch)
                })
                .then((fetch) => {
                    return Promise.all(fetch)
                        .finally(() => {
                            if(!hasFilter(args)) {
                                commit('setUnitsKeysOrdersFullLoad', true)
                            }
                        })
                })
                .then(() => {
                    //dispatch('setLastCall', {name: 'fetchUnitsKeysOrdersAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchUnitsKeysOrdersChanged', inprogress: false})
                })
        },
        fetchUnitsKeysOrdersChanged({dispatch, commit, getters}, args) {
            if (!getters.apiToken) {// || !getters.isUnitsKeysOrdersFullLoad
                return
            }
            dispatch('setLastCall', {name: 'fetchUnitsKeysOrdersChanged', time: Date.now() / 1000})

            args = {...consts.querySettings.filter, ...args}
            return dispatch('fetchUnitsKeysOrders', args)
                .then((data) => {
                    commit('updateUnitsKeysOrders', data)
                    // return dispatch('fetchUnitsKeysOrders', {fields: 'id',expand: ''})
                })
                // .then((data) => {
                //     commit('filterUnitsKeysOrders', data)
                // })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchUnitsKeysOrdersChanged', inprogress: false})
                });
        },
        reloadUnitsKeysOrdersAll({commit, dispatch}, args) {
            commit('clearUnitsKeysOrders')
            return dispatch('fetchUnitsKeysOrdersAllLite', args)
                .then(() => {
                    dispatch('fetchUnitsKeysOrdersAllPages', args)
                })
        },

        fetchUnitsKeysOrder({commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                const params = getApiProps('keysorders', args)
                this.$api.unitskeysorders.find(args.id, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('updateUnitsKeysOrder', response.data)
                            resolve(true)
                        } else {
                            console.error(response);
                            reject(false)
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(false)
                    });
            });
        },

        saveUnitsKeysOrder({dispatch}, key) {
            let fn = (key.id) ? 'updateUnitsKeysOrder' : 'createUnitsKeysOrder'
            return dispatch(fn, key);
        },
        createUnitsKeysOrder({commit, dispatch}, key) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('keysorders')
                this.$api.unitskeysorders.create(key, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('updateUnitsKeysOrder', response.data)
                            dispatch('fetchUnitsKeys4Order', {orderId: response.data?.id})
                            //???
                            dispatch('fetchUnitsKeysOrdersChanged')
                            dispatch('fetchUnitsKeysChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateUnitsKeysOrder({commit, dispatch}, key) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('keysorders')
                this.$api.unitskeysorders.update(key.id, key, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('updateUnitsKeysOrder', response.data)
                            dispatch('fetchUnitsKeys4Order', {orderId: response.data?.id})
                            //???
                            dispatch('fetchUnitsKeysOrdersChanged')
                            dispatch('fetchUnitsKeysChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteUnitsKeysOrder({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                this.$api.unitskeysorders.delete(id)
                    .then((response) => {
                        if (response.status < 400 && (!response.data?.error)) {
                            //commit('deleteUnitsKeysOrder', id)
                            commit('updateUnitsKeysOrder', response.data)
                            dispatch('fetchUnitsKeys4Order', {orderId: response.data?.id})
                            //???
                            dispatch('fetchUnitsKeysOrdersChanged')
                            dispatch('fetchUnitsKeysChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

        transferKeys({dispatch, commit}, order) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('keysorders')
                this.$api.unitskeysorders.transfer(order, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            let orderId = order?.orderId || response?.data?.id || 0
                            commit('updateUnitsKeysOrder', response.data)
                            if (orderId) dispatch('fetchUnitsKeys4Order', {orderId})
                            //???
                            dispatch('fetchUnitsKeysOrdersChanged')
                            dispatch('fetchUnitsKeysChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

        calcUnitsKeysOrdersTimeLeft({commit}) {
            commit('calcUnitsKeysOrdersTimeLeft')
        },

        //sayHello() {}
    },
    mutations: {
        setUnitsKeysOrdersLiteLoad(state, LiteLoad) {
            state.unitsKeysOrdersLiteLoad = state.unitsKeysOrdersLiteLoad || LiteLoad
        },
        setUnitsKeysOrdersFullLoad(state, FullLoad) {
            state.unitsKeysOrdersFullLoad = state.unitsKeysOrdersFullLoad || FullLoad
        },
        setUnitsKeysOrders(state, nOrders) {
            state.unitsKeysOrders = nOrders.map(o => Object.freeze(o))
        },
        updateUnitsKeysOrders(state, nOrders) {
            if (!state.unitsKeysOrders.length) {
                state.unitsKeysOrders = nOrders.map(o => Object.freeze(o))
                return true
            }

            // console.log('updateUnitsKeysOrders', state.unitsKeysOrders.length)
            nOrders.forEach(function (nOrder) {
                let i = state.unitsKeysOrders.findIndex(o => (o.id == nOrder.id))
                if (i < 0) {
                    state.unitsKeysOrders.push(Object.freeze(nOrder))
                } else
                if (!state.unitsKeysOrdersFullLoad || state.unitsKeysOrders[i][changedField] != nOrder[changedField]) {
                    // updateObjectByDiff(state.unitsKeysOrders[i], nOrder)
                    delete nOrder.id
                    state.unitsKeysOrders[i] = Object.freeze({...state.unitsKeysOrders[i], ...nOrder})
                }
            })
        },
        updateUnitsKeysOrder(state, nOrder) {
            let i = state.unitsKeysOrders.findIndex(o => (o.id == nOrder.id))
            if (i < 0) {
                state.unitsKeysOrders.push(Object.freeze(nOrder))
            } else
            if (!state.unitsKeysOrdersFullLoad || state.unitsKeysOrders[i][changedField] != nOrder[changedField]) {
                // updateObjectByDiff(state.unitsKeysOrders[i], nOrder)
                delete nOrder.id
                state.unitsKeysOrders[i] = Object.freeze({...state.unitsKeysOrders[i], ...nOrder})
            }
        },
        deleteUnitsKeysOrder(state, id) {
            let i = state.unitsKeysOrders.findIndex(o => (o.id == id))
            if (i != -1) {
                state.unitsKeysOrders.splice(i, 1)
            }
        },

        calcUnitsKeysOrdersTimeLeft(state) {
            console.error('!!!calcUnitsKeysOrdersTimeLeft')
            state.unitsKeysOrders.forEach((order, i) => {
                order = {...order}
                order.time__left--
                state.unitsKeysOrders[i] = Object.freeze(order)
            })
        },
        clearUnitsKeysOrders(state) {
            state.unitsKeysOrders = []
            state.unitsKeysOrdersLiteLoad = false
            state.unitsKeysOrdersFullLoad = false
        }

    },
    getters: {
        isUnitsKeysOrdersLiteLoad(state) {
            return state.unitsKeysOrdersLiteLoad
        },
        isUnitsKeysOrdersFullLoad(state) {
            return state.unitsKeysOrdersFullLoad
        },
        unitsKeysOrders(state) {
            return state.unitsKeysOrders
        },
        unitsKeysOrdersByIds(state) {
            return state.unitsKeysOrders.reduce((ordersByIds, o) => {
                ordersByIds[o.id] = o
                return ordersByIds
            }, {})
        },
    }
}
