// import Vue from 'vue'
import consts from "@/consts"
import {getApiProps, hasFilter} from "@/lib/lib";
import range from "lodash/range";
// import {objectComparison} from "@/lib/lib";
const changedField = 'changed__time';

export default {
    state: {
        unitsKeysLiteLoad: false,
        unitsKeysFullLoad: false,
        unitsKeys: [],
    },
    actions: {
        fetchUnitsKeys4Order({commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                const params = getApiProps('keys', args)
                this.$api.unitskeysorders.getKeys(args.orderId, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            let order = {orderId: args.orderId, keys: response.data}
                            commit('updateUnitsKeys4Order', order)
                            resolve(true)
                        } else {
                            console.error(response);
                            reject(false)
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(false)
                    });
            });
        },

        fetchUnitsKeys/*all*/({dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchUnitsKeys', time: Date.now() / 1000})

                const params = getApiProps('keys', args)
                this.$api.unitskeys.getAll({...params})
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchUnitsKeys', inprogress: false})
                    });
            })
        },
        fetchUnitsKeysAll({dispatch, commit}, args) {
            return new Promise((resolve, reject) => {
                this.$api.init.getUnitsKeys(args)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('setUnitsKeys', response.data)
                            if(!hasFilter(args)) {
                                commit('setUnitsKeysLiteLoad', true)
                            }
                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchUnitsKeysAll', {})
                        }, 60 * 1000)
                    })
            })
        },
        _fetchUnitsKeysAll({dispatch}, args) {
            return dispatch('fetchUnitsKeysAllLite', args)
        },
        fetchUnitsKeysAllLite({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (getters.isUnitsKeysLiteLoad) {
                    return resolve(getters.unitsKeys.length)
                }
                dispatch('fetchUnitsKeys', {...args, lite: true})
                    .then((data) => {
                        commit('updateUnitsKeys', data)
                        if(!hasFilter(args)) {
                            commit('setUnitsKeysLiteLoad', true)
                        }
                        resolve(data.length)
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchUnitsKeysAllLite', {})
                        }, 60 * 1000)
                    })
            })
        },
        fetchUnitsKeysAllPages({dispatch, commit, getters}, args) {
            //dispatch('setLastCall', {name: 'fetchUnitsKeysAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchUnitsKeysChanged', time: Date.now() / 1000})

            return new Promise((resolve, reject) => {
                    if (!getters.apiToken) {
                        return reject(null)
                    }
                    if (!getters.unitsKeys.length) {
                        return resolve([])
                    }

                    let pageSize = consts.querySettings.pageSize
                    let pages = Math.ceil(getters.unitsKeys.length / pageSize)
                    let fetch = range(pages).map(i => {
                        let page = i + 1;
                        return dispatch('fetchUnitsKeys', {page, 'page-size': pageSize, ...args})
                            .then((data) => {
                                commit('updateUnitsKeys', data)
                            })
                            .catch(() => {
                                dispatch('fetchUnitsKeys', {page, 'page-size': pageSize, ...args})
                            })
                    });
                    resolve(fetch)
                })
                .then((fetch) => {
                    return Promise.all(fetch)
                        .finally(() => {
                            if(!hasFilter(args)) {
                                commit('setUnitsKeysFullLoad', true)
                            }
                        })
                })
                .then(() => {
                    //dispatch('setLastCall', {name: 'fetchUnitsKeysAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchUnitsKeysChanged', inprogress: false})
                })
        },
        fetchUnitsKeysChanged({dispatch, commit, getters}, args) {
            if (!getters.apiToken) {//|| !getters.isUnitsKeysFullLoad
                return
            }
            dispatch('setLastCall', {name: 'fetchUnitsKeysChanged', time: Date.now() / 1000})

            args = {...consts.querySettings.filter, ...args}
            return dispatch('fetchUnitsKeys', args)
                .then((data) => {
                    commit('updateUnitsKeys', data)
                    // return dispatch('fetchUnitsKeys', {fields: 'id',expand: ''})
                })
                // .then((data) => {
                //     commit('filterUnitsKeys', data)
                // })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchUnitsKeysChanged', inprogress: false})
                });
        },
        reloadUnitsKeysAll({commit, dispatch}, args) {
            commit('clearUnitsKeys')
            return dispatch('fetchUnitsKeysAllLite', args)
                .then(() => {
                    dispatch('fetchUnitsKeysAllPages', args)
                })
        },

        saveUnitsKey({dispatch}, key) {
            let fn = (key.id) ? 'updateUnitsKey' : 'createUnitsKey'
            return dispatch(fn, key);
        },
        createUnitsKey({commit, dispatch}, key) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('keys')
                this.$api.unitskeys.create(key, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('updateUnitsKey', response.data)
                            dispatch('fetchUnitsKeysOrder', {id: response.data?.order_id || 0})
                            //???
                            dispatch('fetchUnitsKeysChanged')
                            dispatch('fetchUnitsKeysOrdersChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateUnitsKey({commit, dispatch}, key) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('keys')
                this.$api.unitskeys.update(key.id, key, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('updateUnitsKey', response.data)
                            dispatch('fetchUnitsKeysOrder', {id: response.data?.order_id || 0})
                            //???
                            dispatch('fetchUnitsKeysChanged')
                            dispatch('fetchUnitsKeysOrdersChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteUnitsKey({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                this.$api.unitskeys.delete(id)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('updateUnitsKey', response.data)
                            dispatch('fetchUnitsKeysOrder', {id: response.data?.order_id || 0})
                            //???
                            dispatch('fetchUnitsKeysChanged')
                            dispatch('fetchUnitsKeysOrdersChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

        calcUnitsKeysTimeLeft({commit}) {
            commit('calcUnitsKeysTimeLeft')
        },

        //sayHello() {}
    },
    mutations: {
        setUnitsKeysLiteLoad(state, LiteLoad) {
            state.unitsKeysLiteLoad = state.unitsKeysLiteLoad || LiteLoad
        },
        setUnitsKeysFullLoad(state, FullLoad) {
            state.unitsKeysFullLoad = state.unitsKeysFullLoad || FullLoad
        },
        setUnitsKeys(state, nKeys) {
            state.unitsKeys = nKeys.map(k => Object.freeze(k))
        },
        updateUnitsKeys(state, nKeys) {
            if (!state.unitsKeys.length) {
                state.unitsKeys = nKeys.map(k => Object.freeze(k))
                return true
            }

            // console.log('updateUnitsKeys', state.unitsKeys.length)
            nKeys.forEach(function (nKey) {
                let i = state.unitsKeys.findIndex(k => (k.id == nKey.id))
                if (i < 0) {
                    state.unitsKeys.push(Object.freeze(nKey))
                } else
                if (!state.unitsKeysFullLoad || state.unitsKeys[i][changedField] != nKey[changedField]) {
                    // updateObjectByDiff(state.unitsKeys[i], nKey)
                    delete nKey.id
                    state.unitsKeys[i] = Object.freeze({...state.unitsKeys[i], ...nKey})
                }
            })
        },
        updateUnitsKeys4Order(state, order) {
            let nKeys = order.keys
            let nId = nKeys.map(nk => nk.id)
            let keys = state.unitsKeys.filter(k => k.order_id != order.orderId || nId.includes(k.id))
            //ToDo fix
            if (keys.length !== state.unitsKeys.length) {
                state.unitsKeys = keys.map(k => Object.freeze(k))
            }
            // console.log('updateUnits', state.unitsKeys.length)
            nKeys.forEach(function (nKey) {
                let i = state.unitsKeys.findIndex(k => (k.id == nKey.id))
                if (i < 0) {
                    state.unitsKeys.push(Object.freeze(nKey))
                } else
                if (!state.unitsKeysFullLoad || state.unitsKeys[i][changedField] != nKey[changedField]) {
                    // updateObjectByDiff(state.unitsKeys[i], nKey)
                    delete nKey.id
                    state.unitsKeys[i] = Object.freeze({...state.unitsKeys[i], ...nKey})
                }
            })
        },
        updateUnitsKey(state, nKey) {
            let i = state.unitsKeys.findIndex(k => (k.id == nKey.id))
            if (i < 0) {
                state.unitsKeys.push(Object.freeze(nKey))
            } else
            if (!state.unitsKeysFullLoad || state.unitsKeys[i][changedField] != nKey[changedField]) {
                // updateObjectByDiff(state.unitsKeys[i], nKey)
                delete nKey.id
                state.unitsKeys[i] = Object.freeze({...state.unitsKeys[i], ...nKey})
            }
        },
        deleteUnitsKey(state, id) {
            let i = state.unitsKeys.findIndex(k => (k.id == id))
            if (i != -1) {
                state.unitsKeys.splice(i, 1)
            }
        },

        calcUnitsKeysTimeLeft(state) {
            console.error('!!!calcUnitsKeysTimeLeft')
            state.unitsKeys.forEach((key, i) => {
                key = {...key}
                key.time__left--
                state.unitsKeys[i] = Object.freeze(key)
            })
        },
        clearUnitsKeys(state) {
            state.unitsKeys = []
            state.unitsKeysLiteLoad = false
            state.unitsKeysFullLoad = false
        }

    },
    getters: {
        isUnitsKeysLiteLoad(state) {
            return state.unitsKeysLiteLoad
        },
        isUnitsKeysFullLoad(state) {
            return state.unitsKeysFullLoad
        },
        unitsKeys(state) {
            return state.unitsKeys
        },
        unitsKeysFree(state) {
            return state.unitsKeys.filter(k => k?.status == 'available')
        },

        unitsKeysFreeSum(_, getters) {
            if (getters.isSuperAdmin) return 0
            return getters.unitsKeysFree.length
        },
        unitsKeysFreeByProductsIds(_, getters) {
            if (getters.isSuperAdmin) return []

            return getters.unitsKeysFree.reduce((productKeys, key) => {
                let product = productKeys.find(p => p.productId == key.productId)
                if (!product) {
                    product = {productId: key.productId, keys: []}
                    productKeys.push(product)
                }
                product.keys.push(key.id)
                return productKeys
            }, [])
        },

        unitsKeysFreeByOrdersIds(_, getters) {
            //if (getters.isSuperAdmin) return []

            return getters.unitsKeysFree.reduce((orderKeys, k) => {
                let order = orderKeys.find(o => o.order_id == k.order_id)
                if (!order) {
                    order = {order_id: k.order_id, keys: []}
                    orderKeys.push(order)
                }
                order.keys.push(k.id)
                return orderKeys
            }, [])
        },
        unitsKeysFreeSumText(_, getters) {//ToDo
            if (getters.isSuperAdmin) return {}

            let products = getters.getProducts
            products.sort(function (a, b) {
                return (a.type == b.type) ? a.days - b.days : ((a.type > b.type) ? 1 : -1);
            })

            let balance = {commercial: [], trial: [{name: 'Trial', count: 0, type: 'trial'}]}
            balance = products.reduce((balanceInfo, p) => {
                let keys = getters.unitsKeysFree.filter(k => p.id == k.productId)
                let count = keys.length
                if (count) {
                    if (p.type == 'trial') {
                        balanceInfo.trial[0]['count'] += count
                    } else {
                        let inArray = balanceInfo.commercial.find(t => t.name == p.name)
                        if (inArray) {
                            inArray['count'] += count
                        } else {
                            balanceInfo.commercial.push({name: p.name, count, days: p.days, type: p.type, productId: p.id})
                        }
                    }
                }
                return balanceInfo
            }, balance)
            if (!balance.trial[0]['count']) delete balance.trial

            return balance
        },
        unitsKeysByIds(state) {
            return state.unitsKeys.reduce((keysByIds, k) => {
                keysByIds[k.id] = k
                return keysByIds
            }, {})
        },
    }
}
